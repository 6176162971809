<template>
  <MobileDefault v-if="isMobile">
    <div class="container-bg-white container-space-mobile-default" style="margin-bottom: 0; padding-bottom: 0">
      <h2 class="title mobile-title flex flex-row justify-space-between items-center">
        <div>
          <span class="border-left-line mobile-view"></span>
          <span>Sản phẩm bán chạy</span>
        </div>
<!--        <a-icon type="filter" style="color: #838383"/>-->
      </h2>
<!--      <a-button class="relative" type="primary" size="small" ghost-->
<!--                @click="onClickBtnDownload('product')"-->
<!--                :disabled="isLoadingDownloadCSV"-->
<!--                style="margin: 10px 0; font-size: 10px; height: 28px;"-->
<!--      >-->
<!--        <a-icon :type="isLoadingDownloadCSV ? 'loading' : 'download'"/>-->
<!--        <span>Download danh sách</span>-->
<!--      </a-button>-->
    </div>
    <TableProduct class="container-bg-white" style="margin: 0 12px 12px 12px" :load-type="null"/>
  </MobileDefault>
  <Default v-else>
    <TableProduct class="container-bg-white" style="margin: 0 12px" :load-type="null"/>
  </Default>
</template>

<script>
import MobileDefault from "@/layout/MobileDefault";
import Default from "@/layout/Default";
import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";
import TableProduct from "@/views/search/TableProduct";

export default {
  name: "TableProductView",
  components: {
    MobileDefault,
    Default,
    TableProduct
  },
  mixins: [
    mixinStoreGetterGlobal
  ],
  data() {
    return {
      isLoadingDownloadCSV: null
    }
  },
  methods: {
    onClickBtnDownload () {

    }
  },
  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped>

</style>
